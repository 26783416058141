export const setProfileOpen = 'UI/setProfileOpen';
export const setConvoOpen = 'UI/setConvoOpen';
export const setNavigationOpen = 'UI/setNavigationOpen';
export const addTypingUser = 'UI/addTypingUser';
export const removeTypingUser = 'UI/removeTypingUser';
export const setError = 'UI/setError';
export const receiveInboxSections = 'UI/receiveInboxSections';
export const setFormInProgress = 'UI/setFormInProgress';
export const setModalFormInProgress = 'UI/setModalFormInProgress';
export const setSessionTimeoutModalVisability = 'UI/setSessionTimeoutModalVisability';
export const clearRecordLock = 'UI/clearRecordLock';
export const toggleEmailModal = 'UI/toggleEmailModal';
export const setSystemAlertVisibility = 'UI/setSystemAlertVisibility';
