import React from 'react';
import PropTypes from 'prop-types';
import { Button, Close, Input, Textarea } from 'rhinostyle';
import { Helmet } from 'react-helmet';

import Recaptcha from './Recaptcha';
import { MaskingHelpers } from '../../helpers';
import { modifyLuminance } from '../helpers';
import rhinogramLogoNoText from '../../assets/images/rhinogram-logo--notext.svg';
import RECAPTCHA_SITE_KEY from '../constants/WebWidgetConstants';

// Need this to be a proper component since we have a `ref` we target in the container
class Widget extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      config,
      onCloseClick,
      onSubmitClick,
      handleChange,
      handleResetForm,
      firstName,
      lastName,
      phone,
      question,
      errors,
      response,
      widgetContentRef,
      isValidCaptcha,
      errorMessage,
      loading,
      embed,
    } = this.props;

    const getHeaderTitle = () => {
      if (response === 'success') {
        return 'Thank you!';
      } else if (response === 'error') {
        return 'Error';
      }

      return config.text.formTitle;
    };

    const getWidgetBody = () => {
      if (response === 'success') {
        return (
          <>
            <p>{config.text.confirmationText}</p>
            <Button
              reset
              className="u-text-underline"
              onClick={handleResetForm}
            >
              Send another message
            </Button>
          </>
        );
      } else if (response === 'error') {
        return <p>There was an error processing your request. Please try again later</p>;
      }
      return (
        <>
          {config.text.titleSubtext && <p>{config.text.titleSubtext}</p> }

          <Input
            name="phone"
            placeholder={config.text.phonePlaceholder}
            initialValue={phone || ''}
            onChange={(phoneName, rawValue, value) => handleChange(phoneName, value)}
            type="tel"
            format={MaskingHelpers.phone}
            explanationMessage={config.text.phoneHelpText}
            validationMessage={errors && errors.phone}
            onMouseDown={(e) => e.target.focus()}
          />
          <Textarea
            name="question"
            placeholder={config.text.messagePlaceholder}
            initialValue={question || ''}
            onChange={handleChange}
            validationMessage={errors && errors.question}
            onMouseDown={(e) => e.target.focus()}
          />
          <div className="form__group">
            <div className="row">
              <div className="column-6">
                <Input
                  name="firstName"
                  placeholder="First Name"
                  initialValue={firstName || ''}
                  onChange={handleChange}
                  validationMessage={errors && errors.firstName}
                  onMouseDown={(e) => e.target.focus()}
                />
              </div>
              <div className="column-6">
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  initialValue={lastName || ''}
                  onChange={handleChange}
                  validationMessage={errors && errors.lastName}
                  onMouseDown={(e) => e.target.focus()}
                />
              </div>
            </div>
          </div>
          {embed && (
            <Recaptcha
              id="recaptcha"
              className="g-recaptcha"
              data-sitekey={RECAPTCHA_SITE_KEY}
              validationMessage={!isValidCaptcha ? errorMessage : (errors && errors.reCaptcha)}
            />
          )}
          <Button
            loading={loading}
            block
            className="u-m-t widget__submit-button"
            onClick={onSubmitClick}
          >
            {config.text.submitButton}
          </Button>
        </>
      );
    };

    return (
      <>
        <Helmet>
          <style type="text/css">{`
            ${!response ? `
              .widget__header {
                background: ${config.color.titleBackground};
              }
            ` : ''}

            ${response ? `
              .widget__header {
                padding-bottom: 0;
              }
            ` : ''}

            ${!response ? `
              .widget__header__title {
                color: ${config.color.titleText};
              }

              .widget__close {
                color: ${config.color.titleText} !important;
              }
            ` : ''}

            .widget__submit-button {
              background: ${config.color.buttonBackground};
              color: ${config.color.buttonText};
              border-color: ${modifyLuminance(config.color.buttonBackground, -0.05)};
            }

            .widget__submit-button:hover,
            .widget__submit-button:focus {
              color: ${config.color.buttonText};
              background: ${modifyLuminance(config.color.buttonBackground, -0.025)};
            }
          `}
          </style>
        </Helmet>
        <div className="widget" ref={widgetContentRef}>
          <div className="widget__header">
            {onCloseClick && !config.embedForm.hideCTAButton && (
              <Close
                className="widget__close"
                onClick={onCloseClick}
              />
            )}
            <h3 className="widget__header__title">{getHeaderTitle()}</h3>
          </div>
          <div className="widget__body">
            {getWidgetBody()}
            <div className="u-m-t-large u-text-center u-text-small">
              <a className="widget__bottom-link" href="https://www.rhinogram.com" target="_blank" rel="noopener noreferrer">
                <img className="widget__rg-logo" src={rhinogramLogoNoText} alt="Rhinogram" /><span>Powered by Rhinogram</span>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Widget.propTypes = {
  embed: PropTypes.bool,
  config: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleResetForm: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  question: PropTypes.string,
  errors: PropTypes.object,
  response: PropTypes.string,
  widgetContentRef: PropTypes.func,
  errorMessage: PropTypes.string,
  isValidCaptcha: PropTypes.bool,
  loading: PropTypes.bool,
};

Widget.defaultProps = {
  handleChange: () => {},
};

export default Widget;
