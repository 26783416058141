import { createSlice } from '@reduxjs/toolkit';

import { mergeShallow } from '../helpers/DataHelpers';

import * as AuthActionTypes from '../constants/AuthActionTypes';
import * as ChannelActionTypes from '../constants/ChannelActionTypes';

import * as ChatActionTypes from './chatReducer';
import * as GroupActionTypes from './groupReducer';
import * as OrganizationActionTypes from './organizationReducer';
import * as OutOfOfficeActionTypes from './outOfOfficeReducer';

import * as InboxActionTypes from '../constants/InboxActionTypes';
import * as UserActionTypes from '../constants/UserActionTypes';
import * as SavedContentActionTypes from '../constants/SavedContentActionTypes';
import * as FormActionTypes from '../constants/FormActionTypes';

// SLICE
const phoneSlice = createSlice({
  name: 'PHONE',
  initialState: {
    phones: {},
    phoneIds: [],
  },
  reducers: {
    receivePhones: receivePhonesData,
  },
  extraReducers: {
    [ChannelActionTypes.receiveChannelView]: receivePhonesData,
    [ChannelActionTypes.receiveChannels]: receivePhonesData,
    [ChannelActionTypes.receiveCreateChannel]: receivePhonesData,

    [InboxActionTypes.receiveInboxThreadView]: receivePhonesData,
    [InboxActionTypes.receiveInbox]: receivePhonesData,
    [InboxActionTypes.receiveInboxThread]: receivePhonesData,
    [InboxActionTypes.receiveWebSocketEvent]: receivePhonesData,
    [InboxActionTypes.receiveEventMentions]: receivePhonesData,

    [AuthActionTypes.setUser]: receivePhonesData,

    [ChatActionTypes.receiveChatThreadGroupView]: receivePhonesData,

    [UserActionTypes.receiveMyUsers]: receivePhonesData,
    [UserActionTypes.receiveContactListUsers]: receivePhonesData,
    [UserActionTypes.receiveUsers]: receivePhonesData,
    [UserActionTypes.receiveUsersSearch]: receivePhonesData,
    [UserActionTypes.receiveContactUsers]: receivePhonesData,
    [UserActionTypes.receiveConnectedPartySearch]: receivePhonesData,
    [UserActionTypes.receiveUsersPhonesSearch]: receivePhonesData,
    [UserActionTypes.receiveUser]: receivePhonesData,
    [UserActionTypes.receiveCreateUser]: receivePhonesData,
    [UserActionTypes.receiveUpdateUser]: receivePhonesData,
    [UserActionTypes.receiveMembersView]: receivePhonesData,
    [UserActionTypes.receiveMembersList]: receivePhonesData,
    [UserActionTypes.receiveContactEditFormView]: receivePhonesData,
    [UserActionTypes.receiveUsersModalPhonesSearch]: receivePhonesData,
    [UserActionTypes.receiveContactList]: receivePhonesData,

    [GroupActionTypes.receiveGroups]: receivePhonesData,
    [GroupActionTypes.receiveGroupView]: receivePhonesData,

    [OrganizationActionTypes.receiveOrganizationPreferencesView]: receivePhonesData,

    [OutOfOfficeActionTypes.receiveOOOView]: receivePhonesData,

    [SavedContentActionTypes.receiveEventsForSavedContent]: receivePhonesData,
    [FormActionTypes.receiveContactForms]: receivePhonesData,
  },
});

export default phoneSlice.reducer;

// ACTIONS
export const { receivePhones } = phoneSlice.actions;

// REDUCER HELPERS
function receivePhonesData(state, action) {
  return {
    ...state,
    phones: action.payload.phones ?
      mergeShallow(state.phones, action.payload.phones) : state.phones, // merging due to partial/full phone object scenario with `otherOwners`
    phoneIds: action.payload.phoneIds ? [...new Set([...state.phoneIds, ...action.payload.phoneIds])] : state.phoneIds,
  };
}
