import { createSlice } from '@reduxjs/toolkit';

import { InboxActionTypes, UserActionTypes } from '../constants';
import * as SavedActionTypes from '../constants/SavedContentActionTypes';

// SLICE
const connectedPartySlice = createSlice({
  name: 'CONNECTED_PARTY',
  initialState: {
    connectedParties: {},
    connectedPartyIds: [],
  },
  reducers: {},
  extraReducers: {
    [SavedActionTypes.receiveEventsForSavedContent]: receiveConnectedPartiesData,

    [InboxActionTypes.receiveInboxThreadView]: receiveConnectedPartiesData,

    [UserActionTypes.receiveContactEditFormView]: receiveConnectedPartiesData,
    [UserActionTypes.receiveUsers]: receiveConnectedPartiesData,
    [UserActionTypes.receiveUsersSearch]: receiveConnectedPartiesData,
    [UserActionTypes.receiveContactUsers]: receiveConnectedPartiesData,
    [UserActionTypes.receiveConnectedPartySearch]: receiveConnectedPartiesData,
    [UserActionTypes.receiveUser]: receiveConnectedPartiesData,
    [UserActionTypes.receiveCreateUser]: receiveConnectedPartiesData,
    [UserActionTypes.receiveUpdateUser]: receiveConnectedPartiesData,
    [UserActionTypes.receiveSelectedUser]: receiveConnectedPartiesData,
  },
});

export default connectedPartySlice.reducer;

// Helper functions
function receiveConnectedPartiesData(state, action) {
  return {
    ...state,
    connectedParties: {
      ...state.connectedParties,
      ...action.payload.connectedParties,
    },
    connectedPartyIds: [...new Set([...state.connectedPartyIds, ...action.payload.connectedPartyIds])],
  };
}
