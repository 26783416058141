/* eslint-disable no-console */
import Sockette from 'sockette';
import moment from 'moment-timezone';
import StorageService, { readCookie } from './StorageService';
import store from '../store';
import { UserHelpers } from '../helpers';
import * as AppConstants from '../constants/AppConstants';
import { TYPE_SAVED_CONTENT_SENT } from '../constants/Types';
import * as UIReducer from '../reducers/uiReducer';
import * as UserReducer from '../reducers/userReducer';
import * as ChatReducer from '../reducers/chatReducer';
import * as FormReducer from '../reducers/formReducer';
import * as SystemAlertReducer from '../reducers/systemAlertReducer';
import * as InboxReducer from '../reducers/inboxReducer';
import * as SecureReducer from '../reducers/secureReducer';
import * as RhinovideoReducer from '../reducers/rhinovideoReducer';
import * as SavedContentReducer from '../reducers/savedContentReducer';
import * as RhinocallReducer from '../reducers/rhinocallReducer';
import { getCurrentVideo } from '../selectors/rhinovideoSelectors';
import { getMemberCall } from '../selectors/rhinocallSelector';
import { isMobileSafari } from '../helpers/BrowserHelpers';

const presenceTimeout = 600; // seconds
const typingTimeout = 15000;
const { USER_PRESENCE } = AppConstants;
const rhinosocketUrl = process.env.REACT_APP_RHINOSOCKET_URL;
const SOCKET_STATE_CLOSED = 3;
const SOCKET_STATE_OPEN = 1;

class WebSocketService {
  setup() {
    this.online = false;
    this.userId = StorageService.readEntry('user');
    this.organizationId = StorageService.readEntry('org');
    this.isPatientExperience = StorageService.readEntry('patientExperience');
    this.subscriptions = StorageService.readEntry('subscriptions') || [];
    this.queuedMessages = [];

    let authString = `Cookie:${document.cookie.split(';').map((e) => e.trim()).filter((e) => e.includes('connect.sid'))[0]}`;
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
      authString = `Authorization:Bearer ${accessToken}`;
    }
    this.ws = new Sockette(`${rhinosocketUrl}?auth=${encodeURIComponent(authString)}`,
      {
        timeout: 5e3,
        maxAttempts: 30,
        onopen: (e) => this.onOpen(e),
        onmessage: (e) => this.onMessageReceived(e),
        onreconnect: (e) => this.onReconnect(e),
        onmaximum: (e) => console.log('WebSocket Max Connection Attempts:', e),
        onclose: (e) => console.log('WebSocket Connection Closed:', e),
        onerror: (e) => console.log('WebSocket Error:', e, this.socketState),

      });
    this.startPresenceInterval();
    if (window.Cypress) {
      // in test mode , expose the websocket
      window.WebSocketService = this;
    }
  }

  get typingTimeout() {
    return typingTimeout;
  }

  get presenceTimeout() {
    return presenceTimeout;
  }

  teardown() {
    if (this.ws) {
      this.setOfflinePresence();
      this.ws.close();
    }
  }

  addSubscriptions() {
    const subscribeData = {
      orgId: this.organizationId,
      userId: this.userId,
      subscriptions: this.subscriptions,
      status: USER_PRESENCE.ONLINE,
    };

    const subscribeMessage = {
      action: 'subscribe',
      data: JSON.stringify(subscribeData),
    };

    this.sendMessage(subscribeMessage);
  }
  onOpen(e) {
    this.socketState = e.target;
    console.log('WebSocket Connection Opened:', e);
    this.addSubscriptions();
    // Resend queued messages
    this.processQueuedMessages();
    this.online = true;
  }

  onReconnect(e) {
    console.log('WebSocket Connection Reconnected:', e);
    this.addSubscriptions();
    // Resend queued messages
    this.processQueuedMessages();
    // Refetch thread & inbox events
    store.dispatch(InboxReducer.refetchOnFocus());
    // Check for missed rhinocall events
    this.checkForActiveRhinocall();
  }

  checkForActiveRhinocall() {
    if (isMobileSafari()) {
      store.dispatch(RhinocallReducer.checkActiveMemberCall());
    }
  }

  onMessageReceived = ({ data }) => {
    try {
      const message = JSON.parse(data);
      const state = store.getState();
      const { auth } = state;
      if (message.type === 'subscriptionUpdate') {
        this.subscriptions = message.subscriptions;
        StorageService.createEntry('subscriptions', message.subscriptions);
      } else if (message.type === 'video') {
        if (message.event === 'video:add' && auth.currentUser === message.video?.createdBy) {
          store.dispatch(RhinovideoReducer.setCurrentVideoId(message?.video?.videoId));
        }
        store.dispatch(RhinovideoReducer.getVideoConferences());
      } else if (message.type === 'presence') {
        store.dispatch(UserReducer.shapeUsersPresence(message));
      } else if (message.type === 'typing') {
        const payload = {
          typingUserName: message.name,
          subscribingToUserId: message.subscribingToUserId,
          subscribingToGroupId: message.subscribingToGroupId,
          typingUserId: message.typingUserId,
          date: moment.utc().toISOString(),
        };

        if (message.isTyping) {
          store.dispatch(UIReducer.addTypingUser(payload));
        } else {
          store.dispatch(UIReducer.removeTypingUser(payload));
        }
      } else if (message.type === 'systemAlert') {
        store.dispatch(SystemAlertReducer.receiveWebSocketEventSystemAlert(message));
      } else if (message.type === 'sessionTimeoutWarning') {
        const currentCall = getMemberCall(state);
        const currentVideoExists = !!Object.keys(getCurrentVideo(state))?.length;
        const userSessionId = decodeURIComponent(readCookie('connect.sid')).split('.')[0].replace('s:', '');

        if (currentVideoExists || currentCall) {
          store.dispatch(UserReducer.fetchUser(auth.currentUser, true)); // extend session
        } else if (message.sid === userSessionId) {
          store.dispatch(UIReducer.showSessionTimeoutModal());
        }
      } else if (message.type === 'groupUpdate') {
        store.dispatch(UIReducer.fetchInboxSections());
      } else if (message.type === 'events') {
        if (StorageService.readEntry('patientExperience')) {
          store.dispatch(SecureReducer.receiveWebSocketEventMessage(message));
        } else if (message.event.typeId === TYPE_SAVED_CONTENT_SENT && message.event.statusTypeId) {
          store.dispatch(SavedContentReducer.receiveUpdateSavedContentSentEvent(message));
        } else {
          store.dispatch(InboxReducer.receiveWebsocketEventMessage(message));
        }
      } else if (message.type === 'dms') {
        store.dispatch(ChatReducer.receiveWebSocketEventMessage(message));
      } else if (message.type === 'groups') {
        store.dispatch(UIReducer.fetchInboxSections()); // if a group has updated the user is attached to, re-fetch inbox sections
      } else if (message.type === 'viewing' && message.threadType === 'chat') {
        store.dispatch(ChatReducer.receiveWebSocketEventStatusMessage(message));
      } else if (message.type === 'viewing' && message.threadType === 'inbox') {
        store.dispatch(InboxReducer.receiveWebSocketEventStatusMessage(message));
      } else if (message.type === 'fileUploadPostProcessingDone') {
        store.dispatch(UIReducer.receiveWebSocketFileUploadPostProcessing(message));
      } else if (message.type === 'form') {
        store.dispatch(FormReducer.receiveWebSocketFormUploadPostProcessing(message.data));
      } else if (message.type === 'rhinocall') {
        store.dispatch(RhinocallReducer.receiveWebSocketEventMessage(message));
      }
    } catch (e) {
      console.log('Error receiving message:', e);
    }
  }

  sendMessage(message, resend = true) {
    if (this.ws) {
      try {
        if (this.socketState?.readyState === SOCKET_STATE_OPEN) {
          this.ws.json(message);
        } else {
          if (resend) this.queuedMessages.push(message);
          console.log(`Message queued, socket in state ${this.socketState?.readyState}`);
        }
      } catch (e) {
        console.log('Error sending message:', e);
        if (resend) this.queuedMessages.push(message);
      }
    }
  }

  processQueuedMessages() {
    if (this.queuedMessages.length > 0) {
      this.queuedMessages.forEach((message) => this.sendMessage(message, false)); // only resend once
      this.queuedMessages = []; // reset queue
    }
  }

  updateSubscriptions(updatedSubscriptions) {
    this.sendMessage({ action: 'subscribe', data: JSON.stringify({ subscriptions: updatedSubscriptions }) });
  }

  sendUpdatedTypingState(options) {
    const message = {
      action: 'sendMessage',
      data: JSON.stringify(options),
    };

    this.sendMessage(message);
  }

  shapeOutgoingTypingEvent(options) {
    return {
      type: 'typing',
      typingUserId: Number(options.typingUserId) || Number(options.typingUser.id),
      orgId: this.organizationId,
      subscribingToUserId: Number(options.subscribingToUserId),
      ...options.subscribingToGroupId && {
        subscribingToGroupId: Number(options.subscribingToGroupId),
      },
      ...options.publishingToUserId && { publishingToUserId: options.publishingToUserId },
      threadType: options.threadType,
      isTyping: options.isTyping,
      name: UserHelpers.formatName(options.typingUser),
    };
  }

  shapeSubscriptionForFileUploadPostProcessingEvents(options) {
    return {
      type: 'fileUploadPostProcessingDone',
      orgId: this.organizationId,
      bucket: options.bucket,
      key: options.key,
    };
  }

  // function to subscribe to all things related to a thread
  shapeSubscriptionForTypingEvents(options) {
    return {
      type: 'typing',
      typingUserId: -Number(options.typingUserId),
      threadType: options.threadType,
      orgId: this.organizationId,
      ...options.subscribingToUserId && {
        subscribingToUserId: Number(options.subscribingToUserId),
      },
      ...options.subscribingToGroupId && {
        subscribingToGroupId: Number(options.subscribingToGroupId),
      },
      ...options.publishingToUserId && { publishingToUserId: options.publishingToUserId },
    };
  }

  shapeSubscriptionForViewingThread(options) {
    return {
      type: 'viewing',
      threadType: options.threadType,
      orgId: this.organizationId,
      ...options.subscribingToUserId && {
        subscribingToUserId: Number(options.subscribingToUserId),
      },
      ...options.subscribingToGroupId && {
        subscribingToGroupId: Number(options.subscribingToGroupId),
      },
      ...options.publishingToUserId && { publishingToUserId: options.publishingToUserId },
    };
  }

  setOfflinePresence() {
    this.setAndSendPresence(false);
  }

  setOnlinePresence(history = null) {
    if (this.socketState?.readyState === SOCKET_STATE_CLOSED && history) {
      history.go(0);
    } else {
      this.setAndSendPresence(true);
    }
  }

  /*
    Used to set the user to idle after 10 minutes. Call this function
    to set the user as online and to reset the idle timer.
   */
  resetPresenceTimeout() {
    this.setAndSendPresence(true);
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
    this.timeoutInterval = setInterval(() => {
      this.setOfflinePresence();
    }, 600 * 1000); // 10 minutes
  }

  /*
    This sends the presence every 5 minutes. If the AWS websocket stays idle after
    10 minutes, the connection will timeout.
   */
  startPresenceInterval() {
    if (this.presenceIntervalId) {
      clearInterval(this.presenceIntervalId);
    }
    this.presenceIntervalId = setInterval(() => {
      this.sendPresence();
    }, this.presenceTimeout * 1000); // 5 minutes
  }

  setAndSendPresence(online) {
    const oldOnline = this.online;
    this.online = online;
    if (this.online !== oldOnline) {
      this.sendPresence();
    }
  }

  sendPresence() {
    const status = this.online ? USER_PRESENCE.ONLINE : USER_PRESENCE.OFFLINE;
    const presenceMessage = {
      action: 'sendMessage',
      data: JSON.stringify({
        type: 'presence',
        status,
        userId: this.userId,
        orgId: this.organizationId,
      }),
    };
    this.sendMessage(presenceMessage);
  }
}

export default new WebSocketService();
