// Send data to the React Native webview
class OutboundPostMessageService {
  isBridgeReady() {
    return !!window.ReactNativeWebView;
  }

  postMessage(data) {
    if (this.isBridgeReady()) {
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
  }
}

export default new OutboundPostMessageService();
