export const receiveGroups = 'GROUP/receiveGroups';
export const requestData = 'GROUP/requestData';
export const receiveData = 'GROUP/receiveData';
export const receiveGroupView = 'GROUP/receiveGroupView';
export const requestSearchData = 'GROUP/requestSearchData';
export const receiveGroupsSearch = 'GROUP/receiveGroupsSearch';
export const clearGroupSearch = 'GROUP/clearGroupSearch';
export const resetGroupData = 'GROUP/resetGroupData';
export const removeGroup = 'GROUP/removeGroup';
export const receiveDeleteGroupPreconditions = 'GROUP/receiveDeleteGroupPreconditions';
