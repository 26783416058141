export const resetEventData = 'CHAT/resetEventData';
export const receiveChatInbox = 'CHAT/receiveChatInbox';
export const receiveChatThread = 'CHAT/receiveChatThread';
export const receiveChatThreadUserView = 'CHAT/receiveChatThreadUserView';
export const receiveChatThreadGroupView = 'CHAT/receiveChatThreadGroupView';
export const receiveWebSocketEvent = 'CHAT/receiveWebSocketEvent';
export const receiveCreateChatEvent = 'CHAT/receiveCreateChatEvent';
export const receiveCreateTempChatEvent = 'CHAT/receiveCreateTempChatEvent';
export const receiveWebSocketEventStatus = 'CHAT/receiveWebSocketEventStatus';
export const setMentionStatusLoading = 'CHAT/setMentionStatusLoading';
export const requestChatViewData = 'CHAT/requestChatViewData';
export const requestChatInboxData = 'CHAT/requestChatInboxData';
export const requestChatThreadData = 'CHAT/requestChatThreadData';
export const setChatContext = 'CHAT/setChatContext';
