import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'rhinostyle';
import { Helmet } from 'react-helmet';

import { modifyLuminance } from '../helpers';

// Need this to be a proper component since we have a `ref` we target in the container
class WidgetTrigger extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const {
      config,
      onClick,
      triggerRef,
    } = this.props;

    return (
      <>
        <Helmet>
          <style type="text/css">{`
            .widget__trigger-button {
              background: ${config.color.buttonBackground};
              color: ${config.color.buttonText};
              border-color: ${modifyLuminance(config.color.buttonBackground, -0.05)};
            }

            .widget__trigger-button:hover,
            .widget__trigger-button:focus {
              color: ${config.color.buttonText};
              background: ${modifyLuminance(config.color.buttonBackground, -0.025)};
            }
            html {
              background-color: transparent;
            }
          `}
          </style>
        </Helmet>
        <Button
          className="widget__trigger-button"
          onClick={onClick}
          ref={triggerRef}
        >
          {config.text.calltoActionButton}
        </Button>
      </>
    );
  }
}

WidgetTrigger.propTypes = {
  config: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  triggerRef: PropTypes.func,
};

export default WidgetTrigger;
