import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { routingSlice } from './services/routingService';
import { threadSlice } from './services/threadService';
import { formSlice } from './services/formService';
import { appointmentSlice } from './services/appointmentService';
import { prescriptionSlice } from './services/prescriptionService';
import { campaignSlice } from './services/campaignService';
import { vCardSlice } from './services/vcardService';
import { searchSlice } from './services/searchService';
import { hipaaCampaignSlice } from './services/hipaaCampaignService';
import { getStorageKey } from './selectors/userSelectors';
import { prescriptionReportSlice } from './services/prescriptionReportService';
import { subscriptionSlice } from './services/SubscriptionService';
import StorageService from './services/StorageService';

const saveOptimisticEventsToLocalStorage = (store) => (next) => (action) => {
  const result = next(action);
  const currentState = store.getState();
  const { optimisticEvents } = currentState.inbox;
  if (Array.isArray(optimisticEvents)) {
    const storageKey = getStorageKey(currentState, 'optimisticEvents');
    StorageService.createEntry(storageKey, optimisticEvents);
  }
  return result;
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
    routingSlice.middleware,
    threadSlice.middleware,
    formSlice.middleware,
    appointmentSlice.middleware,
    prescriptionSlice.middleware,
    prescriptionReportSlice.middleware,
    subscriptionSlice.middleware,
    campaignSlice.middleware,
    vCardSlice.middleware,
    searchSlice.middleware,
    hipaaCampaignSlice.middleware,
    saveOptimisticEventsToLocalStorage,
  ],
});

export default store;
