import { NotificationActions } from 'rhinostyle';
import ReactHtmlParser from 'react-html-parser';
import { NORMAL_DISMISS_TIME } from '../constants/AppConstants';
import { replaceNewLinesToLineBreaks } from '../helpers/TemplateHelpers';

export default function NotificationService(action, response, messageData, persistNotification, dismissTime) {
  const {
    method,
    message,
    subText,
  } = handleStatus(action, response, messageData);

  return method(message, null, subText, persistNotification, dismissTime);
}

function handleStatus(action, response, messageData) {
  let returnVal;
  if (response) {
    switch (response.status) {
      case 200:
      case 201:
      case 202:
      case 204:
        returnVal = handleSuccess(action, messageData);
        break;
      case 400:
      case 403:
      case 404:
      case 409:
      case 418:
      case 500:
      case 413:
        returnVal = handleError(action, response.data.message);
        break;
      case 401: // unauthorized - handling this in app.js
        returnVal = null;
        break;
      default:
        returnVal = handleDefault(action, messageData);
    }
  } else {
    returnVal = handleError(action, messageData);
  }

  return returnVal;
}

function handleSuccess(action, messageData) {
  const returnVal = {};

  returnVal.method = notifySuccess;
  returnVal.message = handleResponse(action, 'success', messageData);
  returnVal.subText = handleResponse(action, 'subText', messageData);

  return returnVal;
}

function handleError(action, message) {
  const returnVal = {};

  returnVal.method = notifyError;
  returnVal.message = handleResponse(action, 'error', message);

  return returnVal;
}

function handleDefault(action) {
  const returnVal = {};

  returnVal.method = notifyDefault;
  returnVal.message = handleResponse(action, 'default');

  return returnVal;
}

function handleResponse(action, value, opts) {
  const returnVal = responses(opts)[action][value];
  const formattedText = ReactHtmlParser(replaceNewLinesToLineBreaks(returnVal));
  return formattedText;
}

function responses(opts) {
  // This should be a function that returns a dictionary so we can pass in arguments.
  // Expose opts argument in the case that we want to display specific details in the future and/or server responses.
  // i.e. "Duane Allman was successfully updated"
  return {
    updateGroup: {
      success: 'Group updated successfully',
      default: 'Default response for updating a group',
      error: opts || 'Something went wrong when updating this group',
    },
    createGroup: {
      success: 'Group created successfully',
      default: 'Default response for creating a group',
      error: opts || 'Something went wrong when creating this group',
    },
    updateConsentStatus: {
      error: opts || 'Something went wrong while updating the Consent Status.',
    },
    updateAppointment: {
      success: 'Appointment updated successfully.',
      error: opts || 'Something went wrong while updating this appointment.',
      default: 'Default response for updating an appointment.',
    },
    updateAppointmentType: {
      success: 'Appointment types updated successfully.',
      error: opts || 'Something went wrong while updating the appointment types.',
      default: '',
    },
    updateProvider: {
      success: 'Providers updated successfully.',
      error: opts || 'Something went wrong while updating the providers.',
      default: '',
    },
    updateMember: {
      success: 'Member updated successfully.',
      error: opts || 'Something went wrong while updating this member.',
      default: 'Default response for updating a member.',
    },
    updateContact: {
      success: 'Contact updated successfully.',
      error: opts || 'Something went wrong while updating this contact.',
      default: 'Default response for updating a contact.',
    },
    updateUser: {
      success: 'User updated successfully.',
      error: opts || 'Something went wrong while updating this user.',
      default: 'Default response for updating a user.',
    },
    deleteContact: {
      success: 'Contact successfully deleted.',
      error: 'Something went wrong deleting this contact.',
      default: 'Default response for deleting a contact',
    },
    updateUserPassword: {
      success: 'Password updated successfully.',
      error: opts || 'Something went wrong while updating this password.',
      default: 'Default response for updating a password.',
    },
    updateUserPreferences: {
      success: 'Preferences updated successfully.',
      error: opts || 'Something went wrong while updating preferences.',
      default: 'Default response for updating preferences.',
    },
    createMember: {
      success: 'Member created successfully.',
      error: opts || 'Something went wrong while creating this member.',
      default: 'Default response for creating a member.',
    },
    createContact: {
      success: 'Contact created successfully.',
      error: opts || 'Something went wrong while creating this contact.',
      default: 'Default response for creating a contact.',
    },
    createUser: {
      success: 'User created successfully.',
      error: opts || 'Something went wrong while creating this user.',
      default: 'Default response for creating a user.',
    },
    createOrganization: {
      success: 'Organization created successfully.',
      error: opts || 'Something went wrong while creating this organization.',
      default: 'Default response for creating an organization.',
    },
    updateOrganization: {
      success: 'Organization updated successfully.',
      error: opts || 'Something went wrong while updating this organization.',
      default: 'Default response for updating an organization.',
    },
    createOutOfOffice: {
      success: 'Out of Office created successfully.',
      error: opts || 'Something went wrong while creating this out of office.',
      default: 'Default response for creating an out of office.',
    },
    updateOutOfOffice: {
      success: 'Out of Office updated successfully.',
      error: opts || 'Something went wrong while updating this out of office.',
      default: 'Default response for updating an out of office.',
    },
    destroyOutOfOffice: {
      success: 'Out of Office deleted successfully.',
      error: opts || 'Something went wrong while deleting this out of office.',
      default: 'Default response for deleting an out of office.',
    },
    createOffice: {
      success: 'Office created successfully.',
      error: opts || 'Something went wrong while creating this office.',
      default: 'Default response for creating an office.',
    },
    updateOffice: {
      success: 'Office updated successfully.',
      error: opts || 'Something went wrong while updating this office.',
      default: 'Default response for updating an office.',
    },
    destroyOffice: {
      success: 'Office deleted successfully.',
      error: opts || 'Something went wrong while deleting this office.',
      default: 'Default response for deleting an office.',
    },
    createChannel: {
      success: 'Channel created successfully.',
      error: opts || 'Something went wrong while creating this channel.',
      default: 'Default response for creating an channel.',
    },
    updateChannel: {
      success: 'Channel updated successfully.',
      error: opts || 'Something went wrong while updating this channel.',
      default: 'Default response for updating an channel.',
    },
    facebookAuth: {
      success: 'Facebook channel re-authorized successfully.',
      error: opts || 'Something went wrong while re-authorizing this Facebook channel.',
      default: 'Default response for updating a Facebook channel.',
    },
    instagramAuth: {
      success: 'Instagram channel re-authorized successfully.',
      error: opts || 'Something went wrong while re-authorizing this Instagram channel.',
      default: 'Default response for updating a Instagram channel.',
    },
    destroyChannel: {
      success: 'Channel deleted successfully.',
      error: opts || 'Something went wrong while deleting this channel.',
      default: 'Default response for deleting an channel.',
    },
    destroyGroup: {
      success: 'Group deleted successfully.',
      error: opts || 'Something went wrong while deleting this group.',
      default: 'Default response for deleting a group.',
    },
    createLocation: {
      success: 'Location created successfully.',
      error: opts || 'Something went wrong while creating this location.',
      default: 'Default response for creating an location.',
    },
    updateLocation: {
      success: 'Location updated successfully.',
      error: opts || 'Something went wrong while updating this location.',
      default: 'Default response for updating an location.',
    },
    destroyLocation: {
      success: 'Location deleted successfully.',
      error: opts || 'Something went wrong while deleting this location.',
      default: 'Default response for deleting an location.',
    },
    createLogin: {
      success: 'Login created successfully.',
      error: opts || 'Something went wrong while creating this login.',
      default: 'Default response for creating a login.',
    },
    mergeUser: {
      success: 'Contacts merged successfully.',
      error: opts || 'Something went wrong while merging these contacts.',
      default: 'Default response for merging a contact.',
    },
    createMessageTemplate: {
      success: 'Template created successfully.',
      error: opts || 'Something went wrong while creating this template.',
      default: 'Default response for creating a template.',
    },
    updateMessageTemplate: {
      success: 'Template updated successfully.',
      error: opts || 'Something went wrong while updating this template.',
      default: 'Default response for updating a template.',
    },
    destroyMessageTemplate: {
      success: 'Template deleted successfully.',
      error: opts || 'Something went wrong while deleting this template.',
      default: 'Default response for deleting a template.',
    },
    updateSystemAlert: {
      success: 'System alert updated successfully.',
      error: opts || 'Something went wrong while updating the system alert.',
      default: 'Default response for updating a system alert.',
    },
    updateBillingContact: {
      success: 'Billing Contact updated successfully.',
      error: opts || 'Something went wrong while updating the billing contact.',
      default: 'Default response for updating a billing contact.',
    },
    updateBillingPayment: {
      success: 'Payment Profile updated successfully.',
      error: opts || 'Something went wrong while updating the payment profile.',
      default: 'Default response for updating a payment profile.',
    },
    connectOrgWithCustId: {
      success: 'Connection with Billing Manager successful.',
      error: opts || 'Something went wrong while connecting to Billing Manager.',
      default: 'Default response for connecting to Billing Manager.',
    },
    updateAssignment: {
      success: 'Assignment updated.',
      error: opts || 'Something went wrong while updating the assignment.',
      default: 'Default response for updating an assignment',
    },
    completeAssignment: {
      success: 'Assignment completed.',
      error: 'Something went wrong while completing the assignment.',
      default: 'Default response for completing an assignment',
    },
    closeConversation: {
      success: 'Conversation closed.',
      error: 'Something went wrong while closing the conversation.',
      default: 'Default response for closing a conversation',
    },
    closeMention: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's' : ''} closed.`,
      error: 'Something went wrong while closing the conversations.',
      default: 'Default response for closing conversations',
    },
    closeMentionForAllMembers: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's' : ''} closed.`,
      error: 'Something went wrong while closing the conversations.',
      default: 'Default response for closing conversations',
    },
    updateBulkRead: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's' : ''} marked as read.`,
      error: 'Something went wrong while updating the read status.',
      default: 'Default response for updating read status',
    },
    updateBulkUnRead: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's' : ''} marked as unread.`,
      error: 'Something went wrong while updating the unread status.',
      default: 'Default response for updating unread status',
    },
    updateFollow: {
      success: 'Conversation is now being followed',
      error: 'Something went wrong while updating the follow status.',
      default: 'Default response for updating follow status',
    },
    updateUnfollow: {
      success: 'Conversation has been unfollowed',
      error: 'Something went wrong while updating the follow status.',
      default: 'Default response for updating follow status',
    },
    updateBulkFollow: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's are' : ' is'} now being followed.`,
      error: 'Something went wrong while updating the follow status.',
      default: 'Default response for updating follow status',
    },
    updateBulkUnFollow: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's have' : ' has'} been unfollowed.`,
      error: 'Something went wrong while updating the unfollow status.',
      default: 'Default response for updating unfollow status',
    },
    closeBulkConversations: {
      success: `${opts} Conversation${(opts && opts > 1) ? 's' : ''} closed.`,
      error: 'Something went wrong while closing the conversations.',
      default: 'Default response for closing conversations',
    },
    bulkAssignment: {
      success: `${opts} Message${(opts && opts > 1) ? 's' : ''} assigned.`,
      error: 'Something went wrong while updating the assignments.',
      default: 'Default response for updating assignments.',
    },
    createTag: {
      success: 'Tag created successfully.',
      error: opts || 'Something went wrong while creating this tag.',
      default: 'Default response for creating a tag.',
    },
    replaceTag: {
      success: 'Tag updated successfully.',
      error: opts || 'Something went wrong while updating this tag.',
      default: 'Default response for updating a tag.',
    },
    destroyTag: {
      success: 'Tag deleted successfully.',
      error: opts || 'Something went wrong while deleting this tag.',
      default: 'Default response for deleting a tag.',
    },
    uploadFile: {
      success: 'File uploaded successfully.',
      error: opts || 'Something went wrong while uploading this file.',
      default: 'Default response for uploading a file.',
    },
    importCSV: {
      success: 'file imported successfully.',
      error: opts || 'Something went wrong while importing this file.',
      default: 'Default response for importing a file.',
    },
    updateFavoriteStatusMessageTemplate: {
      success: 'Template status changed successfully.',
      error: opts || 'Something went wrong while changing the status of this template.',
      default: 'Default response for changing status of a template.',
    },
    updateRole: {
      success: 'Role updated successfully.',
      error: opts || 'Something went wrong while updating the role.',
      default: 'Default response for updating role.',
    },
    createRole: {
      success: 'Role created successfully.',
      error: opts || 'Something went wrong while creating the role.',
      default: 'Default response for creating role.',
    },
    deleteRole: {
      success: 'Role deleted successfully.',
      error: opts || 'Something went wrong while deleting the role.',
      default: 'Default response for deleting role.',
    },
    createPaymentRequest: {
      success: 'Payment request sent successfully.',
      error: opts || 'Something went wrong while sending the payment request',
      default: 'Default response for sending a payment request',
    },
    resendPaymentRequest: {
      success: 'Payment request sent successfully.',
      error: opts || 'Something went wrong while sending the payment request',
      default: 'Default response for sending a payment request',
    },
    cancelPaymentRequest: {
      success: 'Payment request cancelled successfully.',
      error: opts || 'Something went wrong while cancelling the payment request',
      default: 'Default response for cancelling a payment request',
    },
    createMerchant: {
      success: 'Rhinopay merchant created successfully.',
      error: opts || 'Something went wrong while creating a Rhinopay merchant.',
      default: 'Default response for creating a merchant.',
    },
    updateMerchant: {
      success: 'Rhinopay merchant updated successfully.',
      error: opts || 'Something went wrong while updating Rhinopay merchant.',
      default: 'Default response for updating a merchant',
    },
    createVideoOrganizationConfiguration: {
      success: 'Video Conference settings updated successfully.',
      error: opts || 'Something went wrong while updating Rhinovideo.',
      default: 'Updated video',
    },
    createCallOrganizationConfiguration: {
      success: 'Call settings updated successfully.',
      error: opts || 'Something went wrong while updating Rhinocall.',
      default: 'Updated call',
    },
    updateVideoOrganizationConfiguration: {
      success: 'Video Conference settings updated successfully.',
      error: opts || 'Something went wrong while updating Rhinovideo.',
      default: 'Updated video',
    },
    updateCallOrganizationConfiguration: {
      success: 'Call settings updated successfully.',
      error: opts || 'Something went wrong while updating Rhinocall.',
      default: 'Updated call',
    },
    createFormOrganizationConfiguration: {
      success: 'Rhinoform created successfully.',
      error: opts || 'Something went wrong while updating Rhinoform.',
      default: 'Created form',
    },
    updateFormOrganizationConfiguration: {
      success: 'Rhinoform updated successfully.',
      error: opts || 'Something went wrong while updating Rhinoform.',
      default: 'Updated Form',
    },
    createVideo: {
      success: 'Video created.',
      error: opts || 'You have reached your video limit. Contact customer support.',
      default: 'Created video',
    },
    timeoutVideo: {
      success: 'Video timedout.',
      error: opts || 'You have reached your video time limit.',
      default: 'Created video',
    },
    microphonePermissions: {
      success: 'Microphone Enabled',
      error: 'Something went wrong while accessing your microphone. Please enable microphone permissions and try again.',
      default: '',
    },
    cameraPermissions: {
      success: 'Camera Enabled',
      error: 'Something went wrong while accessing your camera. Please enable camera permissions and try again.',
      default: '',
    },
    screensharePermissions: {
      success: 'Screenshare Enabled',
      error: 'Something went wrong while trying to share your screen. Please enable screen share permissions and try again.',
      default: 'Screen share permissions are disabled.',
    },
    rhinovideoHost: {
      success: `${opts} is now the Host!`,
      error: 'Something went wrong while trying to update the host.',
      default: '',
    },
    rhinovideoHangup: {
      success: 'call ended.',
      error: 'Something went wrong while trying to hangup.',
      default: '',
    },
    muteParticipant: {
      success: opts,
      error: 'Something went wrong while trying to mute the participant.',
      default: '',
    },
    sendInvite: {
      success: 'Invite sent successfully',
      error: opts || 'Something went wrong while sending invitation.',
      default: 'Default response for sending a invite.',
    },
    createMessage: {
      success: 'Message sent successfully.',
      error: opts || 'Something went wrong while sending message.',
      default: 'Default response for sending a message.',
    },
    createBulkMessage: {
      success: 'RhinoBlast messages are being sent.',
      subText: 'Please allow some time before this activity is displayed',
      error: opts || 'Something went wrong while sending RhinoBlast messages.',
      default: 'Default response for sending RhinoBlast messages.',
    },
    deleteContactList: {
      success: 'Contact list successfully deleted.',
      error: opts || 'Something went wrong while deleting this contact list.',
      default: 'Default response for deleting a contact list.',
    },
    createContactList: {
      success: 'Contact list saved.',
      error: opts || 'Something went wrong while creating this contact list.',
      default: 'Default response for creating a contact.',
    },
    updateContactList: {
      success: 'Contact List Updated.',
      error: opts || 'Something went wrong while creating this contact list.',
      default: 'Default response for creating a contact.',
    },
    createSavedContent: {
      success: 'Selected content saved successfully!',
      error: opts || 'Something went wrong while saving this conversation',
      default: 'Default response for saving conversation content',
    },
    deleteSavedContent: {
      success: 'Selected content deleted successfully!',
      error: opts || 'Something went wrong while deleting this conversation',
      default: 'Default response for deleting conversation content',
    },
    sendSavedContent: {
      success: opts || 'Sending saved content to EHR!',
      error: opts || 'Failed to send saved content to EHR',
      default: 'Default response for sending conversation content',
    },
    rhinoformCreated: {
      success: opts || 'Rhinoform created successfully!',
      error: opts || 'Failed to create Rhinoform',
      default: 'Default response for create Rhinoform',
    },
    rhinoFormUpdated: {
      success: opts || 'Rhinoform updated successfully!',
      error: opts || 'Failed to update Rhinoform',
      default: 'Default response for update Rhinoform',
    },
    fetchFormTemplates: {
      error: opts || 'Failed to fetch form templates',
      default: 'Default response for fetch form templates',
    },
    updateFormTemplate: {
      success: opts || 'Form Template updated successfully!',
      error: opts || 'Failed to update form template',
      default: 'Default response for updating form template',
    },
    formTemplatePublished: {
      success: opts || 'Form published successfully!',
      error: opts || 'Failed to update Form Published',
      default: 'Default response for Published form template',
    },
    deleteFormTemplate: {
      success: opts || 'Form deleted successfully!',
      error: opts || 'Failed to delete form template',
      default: 'Default response for deleting form template',
    },
    createCopySavedContent: {
      success: opts || 'Content copied successfully!',
      error: opts || 'Failed to send saved content to contact',
      default: 'Default response for content copied to content',
    },
    formSend: {
      success: opts || 'Form sent successfully!',
      error: opts || 'Failed to send form to contact',
      default: 'Default response for sending form',
    },
    createAppointmentCampaign: {
      success: opts || 'Appointment reminder created successfully!',
      error: opts || 'Failed to Create appointment reminder',
      default: 'Default response for creating appointment rminder',
    },
    updateAppointmentCampaign: {
      success: opts || 'Appointment reminder updated successfully!',
      error: opts || 'Failed to update appointment reminder',
      default: 'Default response for updating appointment reminder',
    },
    deleteAppointmentCampaign: {
      success: opts || 'Appointment reminder deleted successfully!',
      error: opts || 'Failed to delete appointment reminder',
      default: 'Default response for deleting appointment reminder',
    },
    createCall: {
      success: 'Call created.',
      error: opts || 'Error creating call.',
      default: 'Created call',
    },
    rhinocallHangup: {
      success: 'call ended.',
      error: 'Something went wrong while trying to hangup.',
      default: '',
    },
    rhinocallAudioError: {
      success: '',
      error: 'Error publishing audio. Please hang up & try again.',
      default: '',
    },
    updateCall: {
      success: 'call updated.',
      error: 'Error updating call.',
      default: '',
    },
    updateUserTags: {
      success: `Tags successfully updated for ${opts} Contact${(opts === 1) ? '' : 's'}.`,
      error: opts || 'Error updating user tags.',
      default: '',
    },
    formLinkCopy: {
      success: 'Copied',
      error: 'Error while copying',
      default: '',
    },
    searchUsers: {
      success: '',
      error: opts || 'Error while fetching contacts',
      default: '',
    },
    createCategoryRoute: {
      success: opts || 'Route created successfully!',
      error: opts || 'Failed to create route',
      default: 'Default response for creating route',
    },
    updateCategoryRoute: {
      success: opts || 'Route updated successfully!',
      error: opts || 'Failed to update route',
      default: 'Default response for updating route',
    },
    deleteCategoryRoute: {
      success: opts || 'Route deleted successfully!',
      error: opts || 'Failed to delete route',
      default: 'Default response for deleting route',
    },
    fetchRoutes: {
      success: '',
      error: opts || 'Error while fetching routes',
      default: '',
    },
    fetchCampaigns: {
      success: '',
      error: opts || 'Error while fetching campaigns',
      default: '',
    },
    createPrescriptionCampaign: {
      success: opts || 'Prescription notification created successfully!',
      error: opts || 'Failed to create prescription notification ',
      default: 'Default response for creating prescription notification',
    },
    updatePrescriptionCampaign: {
      success: opts || 'Prescription notification updated successfully!',
      error: opts || 'Failed to update prescription notification ',
      default: 'Default response for updating prescription notification',
    },
    deletePrescriptionCampaign: {
      success: opts || 'Prescription notification deleted successfully!',
      error: opts || 'Failed to delete prescription notification ',
      default: 'Default response for deleting prescription notification',
    },
    createHipaaCampaign: {
      success: opts || 'Hipaa campaign created successfully!',
      error: opts || 'Failed to create HIPAA campaign',
      default: 'Default response for creating HIPAA campaign',
    },
    updateHipaaCampaign: {
      success: opts || 'HIPAA notification updated successfully!',
      error: opts || 'Failed to update HIPAA campaign ',
      default: 'Default response for updating HIPAA campaign',
    },
    deleteHipaaCampaign: {
      success: opts || 'HIPAA campaign deleted successfully!',
      error: opts || 'Failed to delete HIPAA campaign ',
      default: 'Default response for deleting HIPAA campaign',
    },
    fetchRhinophoneToken: {
      error: opts || 'Error fetching organization token',
      default: 'Default response for fetching organization token',
    },
    createSubscription: {
      success: 'Subscription created successfully!',
      error: 'Failed to create a Subscription.',
      default: 'Default response for creating Subscription',
    },
    updateSubscription: {
      success: 'Subscription updated successfully!',
      error: 'Failed to update a Subscription.',
      default: 'Default response for updating Subscription',
    },
  };
}

function notifySuccess(body, type, subText, persistNotification = false, dismissTime = NORMAL_DISMISS_TIME) {
  return NotificationActions.addNotification({
    body: body || 'Success!',
    type: type || 'success',
    subText: subText || '',
    persistNotification,
    dismissTime,
  });
}

function notifyError(body, type, subText, persistNotification = false) {
  return NotificationActions.addNotification({
    body: body || 'Error!',
    type: type || 'danger',
    persistNotification,
  });
}

function notifyDefault(body, type) {
  return NotificationActions.addNotification({
    body: body || 'Default response!',
    type: type || 'default',
  });
}
