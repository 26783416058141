import { AppConstants } from '../../constants';

//-----------
// These are the default error messages to be returned if a validation fails.
// Each input/dropdown/select validationMessage should have a corresponding response.
//-----------

const responses = {
  // User --
  typeId: isRequired('Contact type'),
  firstName: isRequired('First name'),
  lastName: isRequired('Last name'),
  birthday: {
    future: 'A birthday may not be in the future',
    invalid: 'Invalid or missing birthday',
  },
  loginEmail: 'Invalid email address',
  userEmails: 'Invalid email address',
  phone: 'Invalid phone number',
  userPhones: 'Invalid phone number',
  // ----
  // Location --
  state: 'Invalid state',
  zip: 'Invalid zip code',
  name: isRequired('Name'),
  street: isRequired('Street'),
  street1: isRequired('Street'),
  city: 'City should be only letters and less than 30 characters',
  vCardName: isRequired('Display Name'),
  // ----
  // Organization --
  businessEmail: 'Invalid email address',
  businessPhone: 'Invalid phone number',
  contactEmail: 'Invalid email address',
  contactPhone: 'Invalid phone number',
  salesRep: isRequired('Sales Rep'),
  // ---
  // Billing --
  billingStreet1: isRequired('Street'),
  billingCity: isRequired('City'),
  billingState: 'Invalid state',
  billingZip: 'Invalid zip code',
  billingContactFirstName: isRequired('First name'),
  billingContactLastName: isRequired('Last name'),
  billingContactEmail: isRequired('Email'),
  billingContactPhone: 'Invalid phone number',
  billManagerCustId: isRequired('Customer ID'),
  creditCardNumber: isRequired('Credit Card Number'),
  creditCardVerificationValue: isRequired('CVV'),
  creditCardExpYear: isRequired('Expiration Year'),
  creditCardExpMonth: isRequired('Expiration Month'),
  bankName: isRequired('Bank Name'),
  bankAccountNumber: isRequired('Account Number'),
  bankRoutingNumber: isRequired('Routing Number'),
  installAmount: 'Invalid characters',
  // ---
  // Message Template --
  title: isRequired('Title'),
  subject: isRequired('Title'), // Use `title` for this since we want to display that on the frontend
  message: isRequired('Message'),
  // ----
  // Channel --
  channelName: 'Invalid channel name',
  timeZoneId: isRequired('Time zone'),
  route: 'Select at least one route',
  facebookPageId: 'You must select a Facebook page',
  forwardingNumber: 'Enter a forwarding number',
  // ----
  // Group --
  users: 'Select at least one member',
  // ----
  // Org Preferences
  sessionTimeoutMinutes: `Timeout should be between ${AppConstants.SESSION_TIMEOUT_MINUTES_MIN} and ${AppConstants.SESSION_TIMEOUT_MINUTES_MAX} minutes`,
  // ----
  // Web Widget
  question: isRequired('Message'),
  // ----
  // export this function --
  isRequired,
  // reCaptcha verification
  reCaptcha: 'Please verify reCaptcha',
};

// Generic function for a basic response.
function isRequired(prop) {
  return `${prop} is required`;
}

export default responses;
