export const requestOrgData = 'ORG/requestOrgData';
export const receiveOrganization = 'ORG/receiveOrganization';
export const receiveOrganizationPreferencesView = 'ORG/receiveOrganizationPreferencesView';
export const receiveOrganizations = 'ORG/receiveOrganizations';
export const receiveUpdateOrganization = 'ORG/receiveUpdateOrganization';
export const receiveCreateOrganization = 'ORG/receiveCreateOrganization';
export const requestSearchData = 'ORG/requestSearchData';
export const receiveOrgsSearch = 'ORG/receiveOrgsSearch';
export const clearOrgSearch = 'ORG/clearOrgSearch';
export const setOrganizationFormInProgress = 'ORG/setOrganizationFormInProgress';
export const receiveOrganizationProfileView = 'ORG/receiveOrganizationProfileView';
