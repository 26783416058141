import React from 'react';
import PropTypes from 'prop-types';
import { FormValidationMessage } from 'rhinostyle';
import { Helmet } from 'react-helmet';

const ReCAPTCHA = (props) => {
  // consume properties owned by the reCATPCHA, pass the rest to the div so the user can style it.
  const { validationMessage, ...childProps } = props;
  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js" async />
      </Helmet>
      <div className="form__group">
        <div {...childProps} />
        <FormValidationMessage validationMessage={validationMessage} />
      </div>
    </>
  );
};

ReCAPTCHA.propTypes = {
  validationMessage: PropTypes.string,
};

export default ReCAPTCHA;
