export const requestInboxData = 'INBOX/requestInboxData';
export const requestInboxThreadData = 'INBOX/requestInboxThreadData';
export const requestInboxThreadSearchData = 'INBOX/requestInboxThreadSearchData';
export const requestInboxViewData = 'INBOX/requestInboxViewData';

export const receiveInboxThreadView = 'INBOX/receiveInboxThreadView';
export const receiveInboxThread = 'INBOX/receiveInboxThread';

export const receiveSecureMessageThread = 'INBOX/receiveSecureMessageThread';
export const receiveInbox = 'INBOX/receiveInbox';
export const receiveCreateEvent = 'INBOX/receiveCreateEvent';
export const receiveCreateTempEvent = 'INBOX/receiveCreateTempEvent';

export const receiveWebSocketEvent = 'INBOX/receiveWebSocketEvent';
export const receiveWebSocketEventStatus = 'INBOX/receiveWebSocketEventStatus';
export const receiveWebSocketMention = 'INBOX/receiveWebSocketMention';
export const receiveInboxThreadSearch = 'INBOX/receiveInboxThreadSearch';
export const receiveUpdateFollowing = 'INBOX/receiveUpdateFollowing';
export const clearThreadSearch = 'INBOX/clearThreadSearch';

export const setInboxContext = 'INBOX/setInboxContext';
export const setInboxCommunicationContext = 'INBOX/setInboxCommunicationContext';
export const resetEventData = 'INBOX/resetEventData';

export const clearThreadEvents = 'INBOX/clearThreadEvents';
export const removeInboxEvent = 'INBOX/removeInboxEvent';

export const setActiveFromChannel = 'INBOX/setActiveFromChannel';
export const setActiveToChannel = 'INBOX/setActiveToChannel';
export const setSecureMode = 'INBOX/setSecureMode';

export const setActiveSecureFromChannel = 'INBOX/setActiveSecureFromChannel';
export const setActiveSecureNotificationToChannel = 'INBOX/setActiveSecureNotificationToChannel';
export const setActiveSecureNotificationFromChannel = 'INBOX/setActiveSecureNotificationFromChannel';

export const setFilteredChannelIds = 'INBOX/setFilteredChannelIds';

export const clearThreadChannels = 'INBOX/clearThreadChannels'; //
export const clearInboxLoading = 'INBOX/clearInboxLoading'; //

export const receiveTranslatedText = 'INBOX/receiveTranslatedText';
export const saveTranslatedText = 'INBOX/saveTranslatedText'; //

export const receiveRecommendedLanguageId = 'INBOX/receiveRecommendedLanguageId';

export const receiveEventMentions = 'INBOX/receiveEventMentions';
export const receiveMentionReadStatus = 'INBOX/receiveMentionReadStatus';
export const setMentionStatusLoading = 'INBOX/setMentionStatusLoading';

export const requestOpenAssignmentsData = 'INBOX/requestOpenAssignmentsData';
export const receiveOpenAssignments = 'INBOX/receiveOpenAssignments';

export const requestInitialThreadView = 'INBOX/requestInitialThreadView';
