export const receivePatientMenu = 'SECURE/receivePatientMenu';
export const requestSecureViewData = 'SECURE/requestSecureViewData';
export const requestPatientThreadData = 'SECURE/requestPatientThreadData';
export const receiveWebSocketEvent = 'SECURE/receiveWebSocketEvent';
export const receiveCreateEvent = 'SECURE/receiveCreateEvent';
export const receiveCreateTempSecureEvent = 'SECURE/receiveCreateTempSecureEvent';
export const receivePatientThreadChannelView = 'SECURE/receivePatientThreadChannelView';
export const receivePatientThreadAllView = 'SECURE/receivePatientThreadAllView';
export const receivePatientThread = 'SECURE/receivePatientThread';
export const resetEventData = 'SECURE/resetEventData';
export const setSecureContext = 'SECURE/setSecureContext';
