'use strict'; // eslint-disable-line

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  // eslint-disable-next-line global-require
  require('promise/lib/rejection-tracking').enable();
  // eslint-disable-next-line global-require
  window.Promise = require('promise/lib/es6-extensions.js');
}

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// String.includes()
if (!String.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  String.prototype.includes = (search, start) => {
    'use strict'; // eslint-disable-line

    if (typeof start !== 'number') {
      // eslint-disable-next-line no-param-reassign
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}
require('intersection-observer');
require('element-closest');
require('core-js/stable');
require('regenerator-runtime/runtime');
