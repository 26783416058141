/**
 * These methods update the original copy of the data
 * & should not be used for immutable updates
 */

/**
 * @param array, array of ids to be updated
 * @param newId single id, will be added to array if not present
 */
export const addIdToArray = (array, newId) => {
  const idIndex = array.indexOf(newId);
  if (idIndex < 0) {
    array.push(newId);
  }
};

/**
 * @param array, array of ids to be updated
 * @param newId single id, will be removed from array if present
 */
export const deleteIdFromArray = (array, newId) => {
  const idIndex = array.indexOf(newId);
  if (idIndex > -1) {
    array.splice(idIndex, 1);
  }
};
