export const setAuthMode = 'AUTH/setAuthMode';
export const setForgotPasswordEmail = 'AUTH/setForgotPasswordEmail';
export const setUser = 'AUTH/setUser';
export const setOrg = 'AUTH/setOrg';
export const clearAuth = 'AUTH/clearAuth';
export const unsetUser = 'AUTH/unsetUser'; //
export const requestData = 'AUTH/requestData';
export const requestDataEnd = 'AUTH/requestDataEnd';
export const receiveData = 'AUTH/receiveData'; //
export const setLogoutInProgress = 'AUTH/setLogoutInProgress';
export const receiveSSOConnections = 'AUTH/receiveSSOConnections';
