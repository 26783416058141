import { combineReducers } from 'redux';
import analyticsReducer from './analyticsReducer';
import appointmentCampaignReducer from './appointmentCampaignReducer';
import appointmentReducer from './appointmentReducer';
import appointmentTypesReducer from './appointmentTypesReducer';
import auditLogReducer from './auditLogReducer';
import authReducer from './authReducer';
import billingReducer from './billingReducer';
import channelReducer from './channelReducer';
import chatReducer from './chatReducer';
import connectedPartyReducer from './connectedPartyReducer';
import emailReducer from './emailReducer';
import facebookReducer from './facebookReducer';
import formReducer from './formReducer';
import groupReducer from './groupReducer';
import inboxReducer from './inboxReducer';
import instagramReducer from './instagramReducer';
import languageReducer from './languageReducer';
import messageTemplateReducer from './messageTemplateReducer';
import nativeAppReducer from './nativeAppReducer';
import officeReducer from './officeReducer';
import organizationReducer from './organizationReducer';
import outOfOfficeReducer from './outOfOfficeReducer';
import payReducer from './payReducer';
import phoneReducer from './phoneReducer';
import prefixReducer from './prefixReducer';
import rhinogramReducer from './rhinogramReducer';
import rhinocallReducer from './rhinocallReducer';
import rhinovideoReducer from './rhinovideoReducer';
import roleReducer from './roleReducer';
import savedContentReducer from './savedContentReducer';
import secureReducer from './secureReducer';
import stateReducer from './stateReducer';
import suffixReducer from './suffixReducer';
import systemAlertReducer from './systemAlertReducer';
import tagReducer from './tagReducer';
import timeZoneReducer from './timeZoneReducer';
import typeReducer from './typeReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import threadReducer from './threadReducer';
import providerReducer from './providerReducer';
import { routingSlice } from '../services/routingService';
import { threadSlice } from '../services/threadService';
import { formSlice } from '../services/formService';
import { appointmentSlice } from '../services/appointmentService';
import { prescriptionSlice } from '../services/prescriptionService';
import { campaignSlice } from '../services/campaignService';
import { vCardSlice } from '../services/vcardService';
import { searchSlice } from '../services/searchService';
import { hipaaCampaignSlice } from '../services/hipaaCampaignService';
import { prescriptionReportSlice } from '../services/prescriptionReportService';
import { subscriptionSlice } from '../services/SubscriptionService';

const appReducer = combineReducers({
  analytics: analyticsReducer,
  appointment: appointmentReducer,
  appointmentCampaign: appointmentCampaignReducer,
  appointmentTypes: appointmentTypesReducer,
  auditLog: auditLogReducer,
  auth: authReducer,
  billing: billingReducer,
  channel: channelReducer,
  chat: chatReducer,
  connectedParty: connectedPartyReducer,
  email: emailReducer,
  facebook: facebookReducer,
  form: formReducer,
  group: groupReducer,
  inbox: inboxReducer,
  instagram: instagramReducer,
  language: languageReducer,
  messageTemplate: messageTemplateReducer,
  nativeApp: nativeAppReducer,
  office: officeReducer,
  organization: organizationReducer,
  outOfOffice: outOfOfficeReducer,
  pay: payReducer,
  phone: phoneReducer,
  prefix: prefixReducer,
  rhinogram: rhinogramReducer,
  rhinocall: rhinocallReducer,
  rhinovideo: rhinovideoReducer,
  role: roleReducer,
  savedContent: savedContentReducer,
  secure: secureReducer,
  states: stateReducer,
  suffix: suffixReducer,
  systemAlert: systemAlertReducer,
  tag: tagReducer,
  thread: threadReducer,
  timeZone: timeZoneReducer,
  type: typeReducer,
  ui: uiReducer,
  user: userReducer,
  provider: providerReducer,
  [routingSlice.reducerPath]: routingSlice.reducer,
  [threadSlice.reducerPath]: threadSlice.reducer,
  [formSlice.reducerPath]: formSlice.reducer,
  [appointmentSlice.reducerPath]: appointmentSlice.reducer,
  [prescriptionSlice.reducerPath]: prescriptionSlice.reducer,
  [campaignSlice.reducerPath]: campaignSlice.reducer,
  [vCardSlice.reducerPath]: vCardSlice.reducer,
  [searchSlice.reducerPath]: searchSlice.reducer,
  [hipaaCampaignSlice.reducerPath]: hipaaCampaignSlice.reducer,
  [prescriptionReportSlice.reducerPath]: prescriptionReportSlice.reducer,
  [subscriptionSlice.reducerPath]: subscriptionSlice.reducer,
});

const rootReducer = (state, action) => {
  let updatedState = state;

  if (action.type === 'AUTH/clearAuth') {
    updatedState = undefined;
  } else if (action.type === 'AUTH/unsetUser') {
    updatedState = {
      ui: state.ui,
    };
  }

  return appReducer(updatedState, action);
};

export default rootReducer;
