export const receiveMyUsersRaw = 'USER/RECEIVE_MY_USERS_RAW';
export const receiveMyUsers = 'USER/receiveMyUsers';
export const receiveUsers = 'USER/receiveUsers';
export const receiveContactUsers = 'USER/receiveContactUsers';
export const receiveContactListUsers = 'USER/receiveContactListUsers';
export const receiveUser = 'USER/receiveUser';
export const receiveSelectedUser = 'USER/receiveSelectedUser';
export const receiveUsersSearch = 'USER/receiveUsersSearch';
export const receiveUsersStatus = 'USER/receiveUsersStatus';
export const receiveUsersPhonesSearch = 'USER/receiveUsersPhonesSearch';
export const receiveUsersModalPhonesSearch = 'USER/receiveUsersModalPhonesSearch';
export const receiveMembersSearch = 'USER/receiveMembersSearch';
export const receiveMembers = 'USER/receiveMembers';
export const requestMembersSearchData = 'USER/requestMembersSearchData';
export const receiveConnectedPartySearch = 'USER/receiveConnectedPartySearch';
export const requestConnectedPartySearchData = 'USER/requestConnectedPartySearchData';
export const requestData = 'USER/requestData';
export const requestPageData = 'USER/requestPageData';
export const requestSearchData = 'USER/requestSearchData';
export const requestPhoneSearchData = 'USER/requestPhoneSearchData';
export const requestModalPhoneSearchData = 'USER/requestModalPhoneSearchData';
export const setActiveUser = 'USER/setActiveUser';
export const setTypeFilter = 'USER/setTypeFilter';
export const setTagFilter = 'USER/setTagFilter';
export const setAppointmentLoading = 'USER/setAppointmentLoading';
export const setLocationFilter = 'USER/setLocationFilter';
export const receiveCreateUser = 'USER/receiveCreateUser';
export const receiveUpdateUser = 'USER/receiveUpdateUser';
export const clearUser = 'USER/clearUser';
export const clearUsers = 'USER/clearUsers';
export const clearUserSearchResults = 'USER/clearUserSearchResults';
export const setUserSearchError = 'USER/setUserSearchError';
export const removeUser = 'USER/removeUser';
export const receiveMembersView = 'USER/receiveMembersView';
export const receiveContactCreateFormView = 'USER/receiveContactCreateFormView';
export const receiveContactEditFormView = 'USER/receiveContactEditFormView';
export const receiveContactList = 'USER/receiveContactList';
export const removeContactList = 'USER/removeContactList';
export const requestContactListData = 'USER/requestContactListData';
export const receiveCurrentUser = 'USER/receiveCurrentUser';
export const receiveMembersList = 'USER/receiveMembersList';
export const receiveMembersFiltersView = 'USER/receiveMembersFiltersView';
